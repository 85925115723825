import Vue from 'vue'
import Io from 'socket.io-client'
import VueEcho from 'vue-echo-laravel'
var project, obj, articleStatusRegStatus, disasterAlertEventStatus

export default class Socket {
    constructor(_vm) {
        project = _vm
        obj = this
        articleStatusRegStatus = false
        disasterAlertEventStatus = false
    }

    setSocketService () {
        let echoHost = project.socket_uri
        console.log('--> [SOCKET] echoHost: ', echoHost)

        window.io = Io
        let socket = Io(echoHost)

        Vue.use(VueEcho, {
            broadcaster: 'socket.io',
            host: echoHost,
            auth: {
                headers: {
                    'Authorization': 'Bearer ' + project.auth_token
                }
            },
            transports: ['websocket']
        })

        socket.on('connect', obj.socketConnect)
        socket.on('disconnect', obj.socketDisconnect)
        socket.on('reconnect', obj.socketReconnect)
    }

    socketConnect () {
        project.socket_ready = true
        console.log('--> [SOCKET] Socket Connect')
    }

    socketDisconnect () {
        project.socket_ready = false
        console.log('--> [SOCKET] Socket Disconnect')
    }

    socketReconnect () {
        console.log('--> [SOCKET] Socket Reconnect')
    }

    async RegisterDashboardArticleStatusChangeEvent (_callback = false) {
        if (articleStatusRegStatus) {
            return true
        }

        articleStatusRegStatus = true

        let event = '.dashboard.articleEdit'
        let channel = 'dashboard.article.status'
        console.log('--> [SOCKET] 請求訂閱頻道: ', channel, ', 事件: ', event)

        await project.$echo.channel(channel).listen(event, async _response => {
            // console.log("message: ", _response)

            if (typeof _callback === 'function') {
                _callback(_response)
            }
        })
    }

    async RegisterDashboardDisasterAlertEvent (_callback = false) {
        if (disasterAlertEventStatus) {
            return true
        }

        disasterAlertEventStatus = true

        let event = '.dashboard.disasterAlert'
        let channel = 'dashboard.disaster.alert'
        console.log('--> [SOCKET] 請求訂閱頻道: ', channel, ', 事件: ', event)

        await project.$echo.channel(channel).listen(event, async _response => {
            // console.log("message: ", _response)

            if (typeof _callback === 'function') {
                _callback(_response)
            }
        })
    }

    async LeaveEvent (_eventName = '') {
        await project.$echo.leave(_eventName)
    }
}