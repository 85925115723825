import Vue from 'vue'
import FastClick from 'fastclick'
import $ from 'jquery'
import Swal from "sweetalert2"
var project, obj

export default class Common {
    constructor(_vm) {
        project = _vm
        obj = this
    }

    // 載入快速點擊功能
    initFastClick () {
        console.log('=> 載入快速點擊反應功能...')
        if ('addEventListener' in document) {
            document.addEventListener('DOMContentLoaded', function () {
                FastClick.attach(document.body)
            }, false)
        }

        // 處理鍵盤事件帶來的白屏
        $(document).on('blur', 'input', function () { window.scrollTo(0, 0) })
    }

    // 使用原生方法彈出 Alert 功能
    naviteAlert (_title = '', _text = '', _avtOK = false, _buttons = ['OK']) {
        // 針對開發模式進行處理
        if (project.$root.system_version_type === 'dev') {
            alert(_text)
            return
        }

        // 處理預設的 Alert 方法
        if (!_avtOK) {
            _avtOK = this.defaultOK
        }

        // 針對 Android 裝置進行處理
        if (project.$root.platform === 'Android') {
            navigator.notification.confirm(
                _text, // message
                _avtOK, // callback to invoke with index of button pressed
                _title, // title
                _buttons.reverse() // buttonLabels
            )
        }

        // 針對 iOS 裝置進行處理
        if (project.$root.platform === 'iOS') {
            navigator.notification.confirm(
                _text, // message
                _avtOK, // callback to invoke with index of button pressed
                _title, // title
                _buttons // buttonLabels
            )
        }
    }

    // 
    defaultOK () {

    }

    // 處理電話號碼
    getPhoneCode (_countryCode = '+886', _regPhone = '') {
        if (_countryCode === '+886') {
            if (_regPhone.substr(0, 1) === '0') {
                return _countryCode + _regPhone.substr(1)
            } else {
                return _countryCode + _regPhone
            }
        } else {
            return _countryCode + _regPhone
        }
    }

    // 取得 preview URL
    async getMediaPreviewUri (_attachmentID, _onlyUrl = false) {
        if (!_attachmentID) {
            return ''
        }

        let url = project.base_url + 'app/attachment/' + _attachmentID + '/preview?DeviceToken=' + Vue.localStorage.get('api-token')

        if (_onlyUrl) {
            // console.log('=> 返回網址: ' + url)
            return url
        }

        let ext = await project.getAttachmentExt(_attachmentID)
        // console.log('=> 獲取副檔名: ', ext)

        if (!_onlyUrl) {
            // console.log('=> 返回物件: ', { ext: ext, url: url })
            return { ext: ext, url: url }
        }
    }

    // 格式化數字
    formatNumber (num = 0, _format = 2) {
        if (!num) {
            return 0.00
        } else {
            Number.prototype.numberFormat = function (c, d, t) {
                var n = this,
                    c = isNaN(c = Math.abs(c)) ? 2 : c,
                    d = d == undefined ? "." : d,
                    t = t == undefined ? "," : t,
                    s = n < 0 ? "-" : "",
                    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
                    j = (j = i.length) > 3 ? j % 3 : 0;
                return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
            }

            var tempNumber = parseFloat(num)
            var number = tempNumber.numberFormat(_format, '.', ',')
            // console.log('=> formatNumber: ' + num + ' to ' + number)
            return number
        }
    }

    //- 設定 Storage 資料
    setData (_key = '', _val = '') {
        console.log("=> 寫入 LocalStorage Data: " + _key);
        Vue.localStorage.set(_key, _val)
        return true
    }

    //- 取得 Storage 資料
    getData (_key = '') {
        console.log("=> 取得 LocalStorage Data: " + _key);
        return Vue.localStorage.get(_key)
    }

    // 展延 API Token Cookie 的到期時間
    extendApiToken () {
        let apiToken = obj.getCookie('api-token')
        if (apiToken) {
            obj.setCookie('api-token', apiToken)
        }
        return
    }

    //- 設定 Storage 資料
    setCookie (_key = '', _val = '') {
        // 獲得 Cookie 需要對應的網域
        let CookieDomain = process.env.VUE_APP_SESSION_DOMAIN

        // 開發環境時
        if (process.env.VUE_APP_NODE === 'dev') {
            console.log("=> 寫入 Cookie Data: " + _key);
            project.$cookies.set(_key, _val)
        }

        // 雲端環境時
        else {
            console.log("=> 寫入 Cookie Data: " + _key, ', CookieDomain: ', CookieDomain);
            project.$cookies.set(_key, _val, null, null, CookieDomain)
        }

        return true
    }

    //- 取得 Storage 資料
    getCookie (_key = '') {
        console.log("=> 取得 Cookie Data: " + _key);
        return project.$cookies.get(_key)
    }

    // 取得目前時間
    getNowTime (_type = 'datetime', _target = false) {
        // 取得日期
        var today
        if (_target) {
            // 處理數值型態的時間
            if (Number.isInteger(_target)) {
                today = new Date(_target * 1000)
            }
            // 處理文字型態的時間
            else {
                today = new Date(_target)
            }
        } else {
            today = new Date()
        }

        var weeks = ['日', '一', '二', '三', '四', '五', '六']

        var timestamp = Math.floor(today / 1000)
        var nowYear = today.getFullYear()
        var nowMonth = (today.getMonth() + 1)
        var nowDate = today.getDate()
        var nowHour = today.getHours()
        var nowMin = today.getMinutes()
        var nowSec = today.getSeconds()
        var nowWeek = weeks[today.getDay()]

        // 2020-03-03
        if (_type === 'date') {
            return this.paddingLeft(nowYear.toString(), 2) + '-' + this.paddingLeft(nowMonth.toString(), 2) + '-' + this.paddingLeft(nowDate.toString(), 2)
        }

        if (_type === 'date-day') {
            return this.paddingLeft(nowDate.toString(), 2)
        }

        // Week 3
        if (_type === 'week') {
            return today.getDay()
        }

        // 三
        if (_type === 'week2') {
            return nowWeek
        }

        // 04-03(三)
        if (_type === '2date') {
            return this.paddingLeft(nowMonth.toString(), 2) + '/' + this.paddingLeft(nowDate.toString(), 2) + '(' + nowWeek + ')'
        }

        // 04月03日
        if (_type === '3date') {
            return this.paddingLeft(nowMonth.toString(), 2) + '月' + this.paddingLeft(nowDate.toString(), 2) + '日'
        }

        // 2024/04/03(三)
        if (_type === '5date') {
            return nowYear + '/' + this.paddingLeft(nowMonth.toString(), 2) + '/' + this.paddingLeft(nowDate.toString(), 2) + '(星期' + nowWeek + ')'
        }

        // 2020
        if (_type === 'year') {
            return nowYear
        }

        // 01
        if (_type === 'month') {
            return this.paddingLeft(nowMonth.toString(), 2)
        }

        // 2020-03-03 15:15:15
        if (_type === 'datetime') {
            return nowYear + '-'
                + this.paddingLeft(nowMonth.toString(), 2) + '-'
                + this.paddingLeft(nowDate.toString(), 2) + ' '
                + this.paddingLeft(nowHour.toString(), 2) + ':'
                + this.paddingLeft(nowMin.toString(), 2) + ':'
                + this.paddingLeft(nowSec.toString(), 2)
        }

        // 15:15
        if (_type === 'time') {
            return this.paddingLeft(nowHour.toString(), 2) + ':' + this.paddingLeft(nowMin.toString(), 2)
        }

        // timestamp
        if (_type === 'timestamp') {
            return timestamp
        }

        // Date Object
        if (_type === 'Object') {
            return today
        }
    }

    // 人性化時間
    beautifyTime (_time) {
        let timestamp = this.getNowTime('timestamp', _time)
        var mistiming = Math.round(new Date() / 1000) - timestamp;
        var postfix = mistiming > 0 ? '前' : '後'
        mistiming = Math.abs(mistiming)
        var arrr = ['年', '個月', '星期', '天', '小時', '分鐘', '秒'];
        var arrn = [31536000, 2592000, 604800, 86400, 3600, 60, 1];

        for (var i = 0; i < 7; i++) {
            var inm = Math.floor(mistiming / arrn[i])
            if (inm != 0) {
                return inm + '  ' + arrr[i] + postfix
            }
        }
    }

    // 補0
    paddingLeft (str, lenght) {
        if (str.length >= lenght) { return str } else { return this.paddingLeft('0' + str, lenght) }
    }

    // 返回頁面最頂端
    goToPageTop () {
        $('*').animate({ scrollTop: 0 }, 'slow');
    }

    // 使用附檔名獲取檔案MediaType
    getAttachmentMediaType (_ext) {
        let mediaType = ''
        if (_ext === 'mp3') {
            mediaType = 'audio/mpeg'
        }
        if (_ext === 'mp4') {
            mediaType = 'video/mp4'
        }
        if (_ext === 'm3u8') {
            mediaType = 'application/x-mpegURL'
        }
        return mediaType
    }

    // 把陣列的字串值組合成可以帶進去給 SQL 的玩意
    getStringByArrayStr (_array) {
        let string = ""
        _array.forEach(function (_item, _index) {
            if (_index === 0) {
                string += "'" + _item + "'"
            } else {
                string += ",'" + _item + "'"
            }
        })
        return string
    }

    // 處理來自 DB 的回傳結果
    processDbResultData (_result) {
        let searchResults = []
        if (_result.rows['_array']) {
            searchResults = _result.rows['_array']
        } else if (_result.rows) {
            searchResults = _result.rows
        }
        return searchResults
    }

    // 將資料轉成 JSON String
    processDataToJsonString (_data) {
        if (!_data) {
            return "{}"
        }

        return JSON.stringify(_data)
    }

    // 隨機產生字串
    generAtorMoreInt (_len) {
        let password = ''
        let word = '0123456789'
        let len = word.length

        for (let index = 0; index < _len; index++) {
            password += word[Math.floor(Math.random() * len)]
        }

        return password
    }

    // 隨機產生字串
    generAtorMoreEng (_len) {
        let password = ''
        let word = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
        let len = word.length

        for (let index = 0; index < _len; index++) {
            password += word[Math.floor(Math.random() * len)]
        }

        return password
    }

    // 驗證 Email 格式
    validateEmail (_email) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(_email)) {
            return true
        }

        return false
    }

    showMessageNotify (_text) {
        const Toast = Swal.mixin({
            toast: true,
            position: "top-start",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
            }
        });

        Toast.fire({
            icon: "warning",
            title: _text
        });
    }

    showSingleNotify (_text) {
        Swal.fire({
            position: "top-end",
            type: "success",
            title: '<i class="ri-checkbox-circle-fill success-notify-icon" style="font-size: 2em" ></i>',
            text: _text,
            showConfirmButton: false,
            timer: 1500,
        });
    }

    showErrorNotify (_text) {
        Swal.fire({
            icon: 'error',
            title: '遇到了問題',
            text: _text,
            confirmButtonText: "了解"
        })
    }

    showSuccessDialog (_title, _text, _buttonName = false, _methods = false) {
        Swal.fire({
            title: _title,
            html: _text,
            type: "success",
            confirmButtonClass: "btn btn-confirm mt-2",
            confirmButtonText: _buttonName
        }).then((result) => {
            _methods(result)
        })
    }

    showErrorDialog (_text, _buttonName = '瞭解', _methods = false) {
        Swal.fire(_text)
    }

    // 確認型態的彈窗
    confirmAction (_title, _text, _buttonName = '執行', _button2Name = '取消', _methods = function () { }, _methods2 = function () { }) {
        Swal.fire({
            title: _title,
            html: _text,
            type: "warning",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: _buttonName,
            cancelButtonText: _button2Name
        }).then((result) => {
            console.log('=> confirm action:', result)
            if (result.isConfirmed) {
                _methods(result)
            }
            if (result.isDismissed) {
                _methods2(result)
            }
        });
    }

    // 裝置遭到禁止使用
    blockAction () {
        Dialog.alert({
            message: '由於某些原因，我們暫時拒絕這個裝置的使用權限，請洽客服人員',
            theme: 'round-button',
            confirmButtonText: '瞭解',
        }).then(() => {
            // on close
        });
    }

    // 尚未登入拒絕使用功能
    notLoginAlert (_goBackPage = false) {
        Dialog.confirm({
            title: '使用此功能，請先登入哦！',
            confirmButtonText: '登入',
            cancelButtonText: '我考慮一下',
            confirmButtonColor: '#0073B8'
        }).then(() => {
            // 設定需要返回的頁面
            project.setLastPage(project.$route.name, _goBackPage)
            // 設定註記需要在登入後返回指定頁面
            project.needGoBackPage = true
            // 前往登入頁面
            project.goPage('sign-in', true)
        })
            .catch(() => {

            });
    }

    toChineseCharacter (_number) {
        const digit = _number.toString().length
        let unit = ''
        let unitNum = 1

        if (digit >= 9) {
            unit = '億'
            unitNum = 9
        } else if (digit >= 5) {
            unit = '萬'
            unitNum = 5
        } else if (digit >= 4) {
            unit = '千'
            unitNum = 4
        }

        const number = _number / Number(1 + '0'.repeat(unitNum - 1))
        const rounded = Math.round(number * 10) / 10
        return `${rounded}${unit}`
    }

    // loading 開關
    turnLoading (_status, _autoClose = 0) {
        // 自動關閉的秒數設定
        if (_status && _autoClose > 0) {
            setTimeout(() => {
                project.loading_status = false
            }, _autoClose);
        }
    }

    /**
     * 複製指定內容
     * @param {*} _text 
     */
    copyCuponCode (_text, _title = '稿件編號') {
        navigator.clipboard.writeText(_text)
            .then(() => {
                this.showSingleNotify(_title + '已經複製成功囉!')
                // this.$StatusMsg(true, '複製', '您已成功複製優惠碼!')
            })
    }


    /**
     * 以文字搜尋類別對象
     * @param {*} _mainTypeName 
     * @param {*} _subTypeName 
     */
    async searchNewsType (_mainTypeName = "", _subTypeName = "", _success) {
        let searchMainType = {}
        let searchSubType = {}

        // 逐一搜尋主分類
        console.log('=> 請求搜尋主分類[' + _mainTypeName + '],子分類[' + _subTypeName + ']...')
        console.log('=> 全分類清單共有: ' + project.newsTypeList.length + '筆')
        await project.newsTypeList.forEach(async function (_mainType) {
            if (_mainType.name === _mainTypeName) {
                searchMainType = _mainType
                console.log('=> 搜尋到主分類[' + _mainTypeName + ']: ' + _mainType.id)
                // 逐一搜尋子分類
                await _mainType.sub_types.forEach(function (_subType) {
                    if (_subType.name === _subTypeName) {
                        searchSubType = _subType
                        console.log('=> 搜尋到子分類[' + _subTypeName + ']: ' + _subType.id)
                    }
                })
            }
        })

        _success({
            main: searchMainType,
            sub: searchSubType
        })
    }

    async searchNewsTypeByID (_mainTypeID = "", _subTypeID = "", _success) {
        let searchMainType = {}
        let searchSubType = {}

        // 逐一搜尋主分類
        console.log('=> 請求搜尋主分類[' + _mainTypeID + '],子分類[' + _subTypeID + ']...')
        console.log('=> 全分類清單共有: ' + project.newsTypeList.length + '筆')
        await project.newsTypeList.forEach(async function (_mainType) {
            if (_mainType.id === _mainTypeID) {
                searchMainType = _mainType
                console.log('=> 搜尋到主分類[' + _mainTypeID + ']: ' + _mainType.id)
                // 逐一搜尋子分類
                await _mainType.sub_types.forEach(function (_subType) {
                    if (_subType.id === _subTypeID) {
                        searchSubType = _subType
                        console.log('=> 搜尋到子分類[' + _subTypeID + ']: ' + _subType.id)
                    }
                })
            }
        })

        _success({
            main: searchMainType,
            sub: searchSubType
        })
    }

    /**
     * 驗證用戶是否具有此權限
     * @param {*} _accessName 
     * @returns 
     */
    confirmUserAccess (_accessName) {
        if (project.user.permission.indexOf(_accessName) > -1)
            return true

        return false
    }

    /**
     * 設置畫面標題
     * @param {*} _titleText 
     */
    setPageTitle (_titleText = '中天新聞網 | 資訊管理平台') {
        $("title").text(_titleText)
    }

    /**
     * 從指定連結中獲得 YT 播放清單 ID
     * @param {*} _uri 
     */
    getYoutubePlaylistIdByUri (_uri = "") {
        if (!_uri) {
            return ""
        }

        console.log('_uri:', _uri)
        let urlParams = new URL(_uri)
        return urlParams.searchParams.get('list')
    }
}