<template lang="pug">
  .app
    router-view
    .loading-view(v-if="$root.loading")
      b-spinner(
        variant="light"
        style="width: 4.5rem; height: 4.5rem"
        label="Large Spinner"        
      )
      .alert-text 系統作業中
</template>
<script>
export default {
  components: {

  }
}
</script>
<style lang="sass">
.success-notify-icon
    color: #198cf0
.loading-view
  width: 100%
  height: 100%
  position: fixed
  left: 0px
  top: 0px
  background: rgba(0,0,0,.45)
  z-index: 9999
  padding-left: calc( (100% - (4.5rem / 2)) / 2 )
  padding-top: 20%
.alert-text
  color: #fff
  margin-top: 25px
</style>