export default [
    // 登入頁面
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login')
    },

    // ======== 大首頁 =========
    {
        path: '/',
        name: 'MarketingSlot',
        component: () => import('../views/pages/marketing-slot/home-index/home-index')
    },
    //  大首頁第一區
    {
        path: '/home-part-first',
        name: 'HomePartFirst',
        component: () => import('../views/pages/marketing-slot/home-index/home-part-first')
    },
    //  大首頁第二區
    {
        path: '/home-part-second',
        name: 'HomePartSecond',
        component: () => import('../views/pages/marketing-slot/home-index/home-part-second')
    },
    //  大首頁三宮格區
    {
        path: '/home-part-third',
        name: 'HomePartThird',
        component: () => import('../views/pages/marketing-slot/home-index/home-part-third')
    },
    //  大首頁投票區
    {
        path: '/home-part-vote',
        name: 'HomePartVote',
        component: () => import('../views/pages/marketing-slot/home-index/home-part-vote')
    },
    //  大首頁影音節目區
    {
        path: '/home-part-video',
        name: 'HomePartVideo',
        component: () => import('../views/pages/marketing-slot/home-index/home-part-video')
    },

    // ======== 災害通報中心 =========
    {
        path: '/disaster-list',
        name: 'DisasterListPage',
        component: () => import('../views/pages/disaster/list')
    },


    // ======== 分類頁管理 =========
    {
        path: '/types-page',
        name: 'TypesPage',
        component: () => import('../views/pages/marketing-slot/types/types-page')
    },
    // 分類頁設定
    {
        path: '/types-page/setting/:NewsTypePageHashID',
        name: 'TypesSetting',
        component: () => import('../views/pages/marketing-slot/types/types-setting')
    },
    // 分類頁編輯
    {
        path: '/types-page/edit/:NewsTypePageHashID/:TypeHashID/:RegionType',
        name: 'TypesSetting',
        component: () => import('../views/pages/marketing-slot/types/types-edit')
    },

    // ======== 公告 =========
    // 公告列表
    {
        path: '/announcement',
        name: 'Announcement',
        component: () => import('../views/pages/marketing-slot/announcement/announcement')
    },
    // 公告編輯
    {
        path: '/announcement/editor/:NewAnnounceId',
        name: 'Announcement',
        component: () => import('../views/pages/marketing-slot/announcement/announcement-editor')
    },

    // 快訊跑馬燈
    {
        path: '/marquee',
        name: 'Marquee',
        component: () => import('../views/pages/marketing-slot/marquee')
    },
    // 分類文字設定
    {
        path: '/category',
        name: 'Category',
        component: () => import('../views/pages/marketing-slot/category')
    },

    // ======== 新聞/文章編輯 =========
    // 分頁新聞版位設定
    {
        path: '/placement',
        name: 'Placement',
        component: () => import('../views/pages/marketing-slot/placement/placement')
    },
    // 分頁新聞版位設定內頁
    {
        path: '/placement/setting/:NewsTypePageHashID',
        name: 'Placement',
        component: () => import('../views/pages/marketing-slot/placement/placement-setting')
    },

    // ======== 新聞/文章編輯 =========
    // 新聞/文章列表
    {
        path: '/articles',
        name: 'ArticleList',
        component: () => import('../views/pages/Submission-slot/ArticleEditor/ArticleList')
    },
    // 新聞/文章設定
    {
        path: '/article/editor/:NewsHashId',
        name: 'ArticleEditor',
        component: () => import('../views/pages/Submission-slot/ArticleEditor/ArticleEditor')
    },
    // 導購連結設定
    {
        name: 'GuideLinks',
        path: '/guide-links',
        component: () => import('../views/pages/Submission-slot/ArticleEditor/GuideLinkEditor')
    },
    // Tag設定
    {
        path: '/tagsettings',
        name: 'TagSettings',
        component: () => import('../views/pages/Submission-slot/TagSettings')
    },
    // 影音庫
    {
        path: '/videolibrary',
        name: 'VideoLibrary',
        component: () => import('../views/pages/Submission-slot/VideoLibrary')
    },
    // ======== 內部公告 =========
    // 內部公告列表
    {
        path: '/internal-announcements',
        name: 'InternalAnnouncements',
        component: () => import('../views/pages/Submission-slot/internal-announcements/internal-announcements')
    },
    // 內部公告編輯
    {
        path: '/internal-announcements/editor/:NewAnnounceId',
        name: 'InternalAnnouncementsEditor',
        component: () => import('../views/pages/Submission-slot/internal-announcements/internal-announcements-editor')
    },

    // Rss設定
    {
        path: '/rsssettings',
        name: 'RssSettings',
        component: () => import('../views/pages/Submission-slot/RssSettings')
    },
    // 專區直播設定
    {
        path: '/livestream',
        name: 'LiveStream',
        component: () => import('../views/pages/LiveManagement-slot/LiveStream')
    },
    // 直播設定
    {
        path: '/setting',
        name: 'Setting',
        component: () => import('../views/pages/LiveManagement-slot/Setting')
    },
    // 直播設定內頁
    {
        path: '/live-setting/:liveSettingId',
        name: 'LiveSetting',
        component: () => import('../views/pages/LiveManagement-slot/LiveSetting')
    },


    // ======== 節目表 =========
    // 節目表列表
    {
        path: '/program-schedule',
        name: 'ProgramSchedule',
        component: () => import('../views/pages/LiveManagement-slot/ProgramSchedule/ProgramSchedule')
    },
    // 節目表編輯
    {
        path: '/program-schedule/:id',
        name: 'ProgramScheduleEditor',
        component: () => import('../views/pages/LiveManagement-slot/ProgramSchedule/ProgramSchedule-editor')
    },


    // ======== 選擇設定 =========
    // 設定列表
    {
        path: '/election',
        name: 'Election',
        component: () => import('../views/pages/ElectionSection/Election/Election')
    },
    // 選舉設定
    {
        path: '/election-editor',
        name: 'ElectionEditor',
        component: () => import('../views/pages/ElectionSection/Election/Election-editor')
    },
    {
        path: '/election-candidates/:id',
        name: 'ElectionCandidates',
        component: () => import('../views/pages/ElectionSection/Election/Election-candidates')
    },
    {
        path: '/election-partys',
        name: 'ElectionPartys',
        component: () => import('../views/pages/ElectionSection/Election/Election-partys')
    },
    // 候選人帳號設定
    {
        path: '/accountsettings',
        name: 'AccountSettings',
        component: () => import('../views/pages/ElectionSection/AccountSettings')
    },
    // ======== 投票設定 =========
    // 投票編輯
    {
        path: '/management',
        name: 'Management',
        component: () => import('../views/pages/VotingManagement/Management')
    },
    // 投票編輯內頁
    {
        path: '/management-setting/:votingId?',
        name: 'ManagementSetting',
        component: () => import('../views/pages/VotingManagement/Management-setting')
    },
    // ======== 推播管理 =========
    // 推播列表
    {
        path: '/broadcast',
        name: 'BroadcastList',
        component: () => import('../views/pages/Broadcast/BroadcastList')
    },
    // 推播編輯
    {
        path: '/broadcast/editor/:broadcastId',
        name: 'BroadcastEditor',
        component: () => import('../views/pages/Broadcast/BroadcastEditor')
    },
    // Line 推播列表
    {
        path: '/line-broadcast',
        name: 'LineBroadCastList',
        component: () => import('../views/pages/Broadcast/LineBroadcastList')
    },
    // Line 推播編輯
    {
        path: '/line-broadcast/editor/:broadcastId',
        name: 'LineBroadCastEditor',
        component: () => import('../views/pages/Broadcast/LineBroadcastEditor')
    },

    // 用戶查詢
    {
        path: '/usermanagement',
        name: 'UserManagement',
        component: () => import('../views/pages/TaskManagement/UserManagement/UserManagement')
    },
    // 個人帳戶資料
    {
        path: '/usermanagement/setting/:memberHashID',
        name: 'UserManagementSetting',
        component: () => import('../views/pages/TaskManagement/UserManagement/UserManagementSetting')
    },

    // 旺幣查詢
    {
        path: '/wancoin',
        name: 'Wancoin',
        component: () => import('../views/pages/TaskManagement/Wancoin')
    },

    // ======== 旺幣任務設定 =========
    // 旺幣任務設定
    {
        path: '/wanciontask',
        name: 'WancionTask',
        component: () => import('../views/pages/TaskManagement/WancionTask/WancionTask')
    },
    // 旺幣任務編輯
    {
        path: '/wanciontask/setting/:questHashID?',
        name: 'WancionTaskSetting',
        component: () => import('../views/pages/TaskManagement/WancionTask/WancionTaskSetting')
    },


    // ======== 旺幣兌換設定 =========
    // 旺幣兌換設定/查詢
    {
        path: '/wancionexchange',
        name: 'WancionExchange',
        component: () => import('../views/pages/TaskManagement/WancionExchange/WancionExchange')
    },
    // 旺幣兌換設定/查詢
    {
        path: '/wancionexchange/setting/:promotionalItemHashID?',
        name: 'WancionExchangeSetting',
        component: () => import('../views/pages/TaskManagement/WancionExchange/WancionExchangeSetting')
    },
    // 旺幣兌換已兌換清單
    {
        path: '/wancionexchangeList/:promotionalItemHashID',
        name: 'WancionExchangeList',
        component: () => import('../views/pages/TaskManagement/WancionExchange/WancionExchangeList')
    },

    // 升等任務查詢
    {
        path: '/upgradetask',
        name: 'UpgradeTask',
        component: () => import('../views/pages/TaskManagement/UpgradeTask')
    },
    // 專區首頁編輯
    {
        path: '/homeeditor',
        name: 'HomeEditor',
        component: () => import('../views/pages/VideoProgramManagement/HomeEditor')
    },
    // 節目設定
    {
        path: '/programsetting',
        name: 'ProgramSetting',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/VideoProgramManagement/ProgramSetting')
    },
    // 節目設定內頁
    {
        path: '/programsetting-edit/:programId',
        name: 'ProgramEdit',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/VideoProgramManagement/ProgramEdit')
    },
    // 留言管理
    {
        path: '/msgmanagement',
        name: 'MsgManagement',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/MsgChatroomManagement/MsgManagement')
    },
    // 聊天室管理
    {
        path: '/chatroommanagement',
        name: 'ChatRoomManagement',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/MsgChatroomManagement/ChatRoomManagement')
    },
    // 封鎖詞管理
    {
        path: '/blockwordmanagemnet',
        name: 'BlockwordManagemnet',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/MsgChatroomManagement/BlockwordManagemnet')
    },
    // 訂單管理
    {
        path: '/ordermanagement',
        name: 'OrderManagement',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/SponsorshipManagement/OrderManagement')
    },
    // 查看訂單
    {
        path: '/ordermanagement-setting/:id',
        name: 'OrderManagementSetting',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/SponsorshipManagement/OrderManagementSetting')
    },


    // 帳號/權限管理
    {
        path: '/apmanagement',
        name: 'APManagement',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/A&PManagement/APManagement')
    },

    // 帳號/權限編輯
    {
        path: '/apmanagement-setting/:id',
        name: 'APManagementSetting',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/A&PManagement/APManagementSetting')
    },

    // 線上詢問列表
    {
        path: '/onlineinquiry',
        name: 'OnlineInquiry',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/CustomerServiceManagement/OnlineInquiry')
    },
    {
        path: '/onlineinquiry/setting/:contactOrderHashID',
        name: 'OnlineInquirySetting',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/CustomerServiceManagement/OnlineInquirySetting')
    },
    // ======== 數據分析 =========
    // dashboard
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: () => import('../views/pages/DataAnalysis/Dashboard')
    },
    // 新聞數據查詢
    {
        path: '/news-analysis',
        name: 'NewsAnalysis',
        component: () => import('../views/pages/DataAnalysis/NewsAnalysis')
    },
    // 各中心數據查詢
    {
      path: '/center-analysis',
      name: 'CenterAnalysis',
      component: () => import('../views/pages/DataAnalysis/CenterAnalysis')
    },
    {
      path: '/center-analysis/detail/center/:centerId',
      name: 'CenterAnalysisCenterDetail',
      component: () => import('../views/pages/DataAnalysis/CenterAnalysisCenterDetail')
    },
    {
      path: '/center-analysis/detail/editer/:editerId',
      name: 'CenterAnalysisEditerDetail',
      component: () => import('../views/pages/DataAnalysis/CenterAnalysisEditerDetail')
    },
    // 外稿資料
    {
      path: '/external-content-analysis',
      name: 'ExternalContentAnalysis',
      component: () => import('../views/pages/DataAnalysis/ExternalContentAnalysis')
    },
    {
      path: '/external-content-analysis/detail',
      name: 'ExternalContentAnalysisDetail',
      component: () => import('../views/pages/DataAnalysis/ExternalContentAnalysisDetail')
    },
    // 影音節目數據查詢
    {
      path: '/media-program-analysis',
      name: 'MediaProgramAnalysis',
      component: () => import('../views/pages/DataAnalysis/MediaProgramAnalysis')
    },
    // 流量/訪客
    {
      path: '/traffic-analysis',
      name: 'TrafficAnalysis',
      component: () => import('../views/pages/DataAnalysis/TrafficAnalysis')
    },

    // ======== 錯誤頁面相關 =========
    {
        path: '*',
        name: 'PageNotFound',
        meta: {},
        component: () => import('../views/pages/error/404-alt')
    }
]
